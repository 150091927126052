@import "mixins";
// First hero
.auction_wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 10px auto;
  justify-content: center;
  font-size: 16px;
  @include extraLarge {
    font-size: 18px;
  }
  @include medium {
    flex-direction: row;
    align-items: center;
  }
}

.art_info {
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-top: 1em;
  @include medium {
    margin-top: none;
    margin-left: 52px;
  }
}

.artist_name {
  font-family: "Bebas Neue Pro Expanded";
  text-transform: uppercase;
  // font-weight: bold;
  letter-spacing: 1.5px;
  font-size: 1.25em;
}

.avatar_wrapper {
  display: flex;
  align-items: center;
  margin: 8px 0 18px;
  @include medium {
    margin: 0;
  }
  > span {
    margin-left: 8px;
  }
  > :first-child {
    border: 2px solid black;
  }
}
.artist_name_wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  position: relative;
  font-size: 1.1em;
  @include medium {
    margin-left: 0.8em;
  }
  span {
    &:first-child {
      opacity: 0.7;
      font-size: 0.5em;
      @include medium {
        font-size: 0.65em;
      }
    }
  }
}

.art_title {
  font-family: "Open Sans";
  font-weight: 600;
  text-transform: none;
  font-size: 2.25em;
  @include medium {
    font-size: 2.75em;
  }
  margin: 12px 0;
}

.buttons {
  margin-top: 14px;
  font-size: 1.1em;
  @include large {
    font-size: 1.3em;
  }
  display: flex;
  flex-wrap: wrap;
  button {
    :is(&, a) + button {
      @include medium {
        margin-left: 14px;
      }
    }
    @include mobile-only {
      &.first {
      }
      &.second {
        flex: 1;
      }
      &.third {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}

// Featured artists
.featured_auction {
  @include mobile-only {
    > * {
      width: 95%;
      margin: auto;
    }
  }
  > div > h2 {
    text-align: center;
    font-size: 2.25em;
    @include medium {
      font-size: 2.75em;
      white-space: nowrap;
    }
    letter-spacing: 1.1px;
    user-select: none;
    margin-bottom: 1em;
    margin-top: 0;
  }
}
.featured_wrapper {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  position: relative;
  > div {
    padding-bottom: 3.5em;
    &:first-of-type {
      .artist_info_container {
        &::after {
          content: none;
        }
      }
    }
  }
  @include medium {
    font-size: 18px;
  }
  h2 {
    text-align: center;
    font-size: 2.25em;
    @include medium {
      margin-bottom: 0;
      font-size: 2.75em;
    }
    letter-spacing: 1.1px;
    white-space: nowrap;
    user-select: none;
  }
}
$COLOR_OFF: hsla(0, 0%, 100%, 0.2);
$COLOR_ON: white;
.bottom_line {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    bottom: 10px;
    @include mobile-only {
      width: 100%;
    }
    width: calc(100% - 18px);
    height: 1px;
    background-color: rgba(255 255 255 / 1);
    box-shadow: 0 0 10.1px 1px rgba(192 219 255 / 48%),
      0 0 8.1px 1px rgb(65 120 255 / 24%);
    border-radius: 2px;
  }
  margin-bottom: 20px;
}
.artist_info_container {
  margin-top: 14px;
  @include mobile-only {
    margin-bottom: 1.5em;
  }
  @include medium {
    margin-top: 32px;
  }
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: -30px;
    @include medium {
      top: -40px;
    }
    @include mobile-only {
      width: 100%;
    }
    width: calc(100% - 18px);
    right: 50%;
    transform: translateX(50%);
    height: 1px;
    background-color: rgba(255 255 255 / 1);
    box-shadow: 0 0 10.1px 1px rgba(192 219 255 / 48%),
      0 0 8.1px 1px rgb(65 120 255 / 24%);
    border-radius: 2px;
  }
  .avatar {
    position: relative;
  }
}
.button_wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  font-size: 1.3em;
  margin-top: 32px;
}
.artist_info {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  @include medium {
    margin-left: 12px;
    margin-bottom: 32px;
  }
  img {
    z-index: 9;
  }
  > div.names {
    margin-left: 10px;
    display: flex;
    font-size: 1.25em;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    flex: 1;
    margin-left: 12px;
  }
  @include bigger_first_letter($FONT_SIZE_BASE: 1.5em);
  h2 {
    & + h2 {
      margin-left: 1ch;
    }
    display: inline-block;
    margin: 0;
    font-family: "Bebas Neue Pro Expanded", sans-serif;
    letter-spacing: 1.1px;
    // padding-bottom: 16px;
  }
  p {
    margin: 0px;
    font-family: "Bebas Neue Pro Expanded", sans-serif;
    font-weight: 300;
    letter-spacing: 0.7px;
    display: flex;
    align-items: center;
    svg {
      margin-right: 4px;
    }
  }
}

.image_wrapper {
  display: flex;
  justify-content: space-between;
  @include mobile-only {
    flex-direction: column;
  }
  > div {
    flex: 1;
    position: relative;
    @include mobile-only {
      & + div {
        margin-top: 3em;
      }
    }
    @include medium {
      max-width: 300px;
      margin: 0 12px;
    }
    p {
      margin: 0;
      margin-top: 12px;
      text-align: center;
      @include mobile-only {
        font-size: 1.8em;
      }
      font-size: 1.5em;
      font-weight: bold;
      letter-spacing: 0.9px;
      font-family: "Bebas Neue Pro Expanded";
      // white-space: nowrap;
    }
    &:hover {
      & img,
      video {
        transform: scale(1.01);
      }
    }
  }
  img,
  video {
    z-index: 1;
    transition: transform 0.1s linear;
    border-radius: 5px;
    box-shadow: 0 0 0.1px 2px white;
  }
}

.how_to_buy {
  display: flex;
  flex-direction: column;
  h2 {
    text-align: center;
    font-size: 2.25em;
    @include medium {
      font-size: 2.75em;
      white-space: nowrap;
    }
    letter-spacing: 1.1px;
    user-select: none;
    margin-top: 0;
    margin-bottom: 0.1em;
  }
  h2 + p {
    margin-bottom: 1em;
    font-size: 1.15em;
  }
  p {
    max-width: 50ch;
    text-align: center;
    margin: auto;
  }
  .options {
    font-size: 1.1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    @include medium {
      flex-direction: row;
    }
    a {
      text-decoration: underline;
      white-space: nowrap;
    }
    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      button {
        height: min-content;
      }
      h3 {
        font-size: 1.5em;
        width: max-content;
      }
    }
  }
}
